@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
  .btn {
    @apply text-gray-800 font-sans font-black text-lg self-center;
  }
  .btn-sib {
    @apply py-2 bg-yellow-200 absolute top-4 -right-4 -left-4 rounded-full z-[-1];
  }
  .cs-width {
    @apply w-10/12 mx-auto md:flex flex-col gap-7 items-center md:items-start lg:items-center;
  }
  .flex-layout {
    @apply flex flex-col gap-7 items-center;
  }

  /* themes */
  .primary-dark {
    @apply bg-gray-800;
  }
  .secondary-gray {
    @apply bg-gray-400;
  }
  .text-low-opacity {
    @apply opacity-70;
  }
  .lg-txt {
    @apply font-sans font-black text-5xl md:text-7xl;
  }
  .lg-txt-x {
    @apply font-sans font-black text-5xl;
  }
  .lg-txt-m {
    @apply font-sans font-black text-3xl;
  }
  .md-txt {
    @apply font-sans font-black text-xl capitalize;
  }
  .sm-txt {
    @apply font-sans font-normal text-base md:text-lg xl:text-xl;
  }
  .ptop {
    @apply pt-5;
  }
  .sec-div {
    @apply cs-width md:w-1/2 md:px-10 md:py-10 lg:py-20;
  }
  .sec-reg {
    @apply flex flex-col gap-20 md:flex-row md:gap-0 lg:w-4/5 lg:mx-auto;
  }
  .sec-rev {
    @apply flex flex-col gap-20 md:gap-0 md:flex-row-reverse lg:w-4/5 lg:mx-auto lg:items-end;
  }
  .bg-t {
    @apply absolute top-0 left-0 right-0 h-28;
  }
  .bg-b {
    @apply absolute bottom-0 left-0 right-0 h-28;
  }
  /* font style */

  .large-font1 {
    @apply font-black text-6xl md:text-8xl capitalize;
  }
  .large-font2 {
    @apply font-black text-4xl md:text-6xl capitalize;
  }
  .small-font1 {
    @apply font-bold text-2xl md:text-4xl capitalize;
  }
  .small-mono2 {
    @apply font-normal text-base md:text-lg capitalize;
  }
   /* .default {
    @apply text-slate-50 bg-slate-950 font-light sm-txt small-mono
  }  */
  /* themes */
}

 * {
  box-sizing: border-box;
} 

.heroBg {
  background-image: url('/public/mountain.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cbg {
  background-image: url("/public/mountain.jpg");
}

.myGrid {
  display: grid;
   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr)); 
  gap: 5rem;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
